@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';
@import "~@angular/material/theming";
/* @import "../node_modules/angular-calendar/css/angular-calendar.css"; */

/* ------------------------ font Import -----------------------------------*/

@font-face {
	font-family: Poppins-Regular;
	src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
	font-family: Poppins-Medium;
	src: url('assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
	font-family: Poppins-Bold;
	src: url('assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
	font-family: Poppins-SemiBold;
	src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
	font-family: Poppins-Light;
	src: url('assets/fonts/Poppins/Poppins-Light.ttf');
}
$custom-typography: mat-typography-config($font-family: '"Poppins-Regular", sans-serif;');
@include mat-core($custom-typography);

.mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  circle {
    stroke: #1B676B;
  }
}

.tooltip {
	display: inline-block;
	position: relative;
	border-bottom: 1px dotted #666;
	text-align: left;
}

.tooltip h3 {
	margin: 12px 0;
}

.tooltip .right {
	min-width: 200px;
	max-width: 400px;
	top: 50%;
	left: 100%;
	margin-left: 20px;
	transform: translate(0, -50%);
	padding: 0;
	color: #EEEEEE;
	background-color: #444444;
	font-weight: normal;
	font-size: 13px;
	border-radius: 8px;
	position: absolute;
	z-index: 99999999;
	box-sizing: border-box;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.8s;
}

.tooltip:hover .right {
	visibility: visible;
	opacity: 1;
}

.tooltip .right img {
	width: 400px;
	border-radius: 8px 8px 0 0;
}

.tooltip .text-content {
	padding: 10px 20px;
}

.tooltip .right i {
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -12px;
	width: 12px;
	height: 24px;
	overflow: hidden;
}

.tooltip .right i::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	left: 0;
	top: 50%;
	transform: translate(50%, -50%) rotate(-45deg);
	background-color: #444444;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/*################################################ Global Styling #########################################*/

html {
	height: auto;
	font-family: "Poppins-Regular", sans-serif !important;
}

:root {
	font-family: "Poppins-Regular", sans-serif !important;
}

body {
	/* background:url('assets/svg/bg.svg') repeat fixed;  */
	font-family: "Poppins-Regular", sans-serif !important;
	/* background: url("assets/svg/bg.svg") repeat center center fixed; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	font-size: 0.875rem;
	color: #195659;
	height: 100vh;
	width: 100%;
}

body::after {
	position: fixed;
	background-color: #F5F8F8;
	content: "";
	display: block;
	top: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	z-index: -1;
	/* opacity: 0.7; */
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

h1, .h1 {
	font-size: 2.25rem;
}

h2, .h2 {
	font-size: 1.5rem;
}

h3, .h3 {
	font-size: 1.5rem;
}

h4, .h4 {
	font-size: 1.125rem;
}

h5, .h5 {
	font-size: 0.875rem;
}

h6, .h6 {
	font-size: 0.75rem;
}

a:hover, a:active, a:focus {
	text-decoration: none;
	color: #9CB1B3;
	outline: none;
}

.break {
	margin: 2px;
	line-break: normal;
}

.form-control {
	border-radius: 0;
	box-shadow: none;
	font-weight: 550;
	padding-bottom: 0.625rem;
	color: #1B676B;
	font-size: 0.875rem;
	min-height: 47px;
	border: 0px;
	background-color: transparent;
}

.form-control:focus {
	border-color: #9CB1B3;
	box-shadow: none;
	outline: 0 none;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #fff;
}

label {
	/* height: 21px; */
	padding-left: 0.625rem;
	color: #1B676B;
	font-family: Poppins-Regular;
	font-size: 13px;
	line-height: 20px;
}

.form-group input:placeholder-shown {
	height: 24px;
	color: #9CB1B3;
	font-size: 0.875rem;
	line-height: 24px;
	width: 100%;
}

.form-group input::placeholder {
	height: 24px;
	color: #9CB1B3;
	font-size: 0.875rem;
}

.form-group textarea::placeholder {
	color: #9CB1B3;
}

.form-group input:focus {
	border: 1px solid #1B676B;
}

::-moz-placeholder {
	height: 24px;
	color: #9CB1B3;
	font-size: 0.875rem;
	line-height: 24px;
}

:-ms-input-placeholder {
	height: 24px;
	color: #9CB1B3;
	font-size: 0.875rem;
	line-height: 24px;
}

:-moz-placeholder {
	height: 24px;
	color: #9CB1B3;
	font-size: 0.875rem;
	line-height: 24px;
}

a {
	color: #9CB1B3;
}

input, button, a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}

input, button {
	outline: none;
}

.form-group input[type=text], .form-group input[type=password], .form-group input[type=email], .form-group input[type=tel] {
	border: none;
	/* color: #195659; */
	background-color: #f5f8f8;
	height: 47px;
}

.form-group input[type=number] {
	border: none;
	color: #195659;
	background-color: #f5f8f8;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: texfield;
	height: 47px;
}

.form-group input:focus, .form-group textarea:focus {
	border: 1px solid #1B676B;
}

.inline-group input[type=text], .inline-group input[type=password], .inline-group input[type=email], .inline-group input[type=tel] {
	border: none;
	font-size: 0.9375rem;
	padding-left: 0.625rem;
	background-color: #fff;
	height: 47px;
}

.inline-group select {
	border: none;
	padding: 0px 30px 0px 15px;
	border-radius: 25px;
	min-height: 45px;
	font-size: 1rem;
	background-color: #fff;
	color: #1B676B;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: flex;
	background: url("assets/svg/arrow.svg") #fff no-repeat right center !important;
	/* !important used for overriding all other customisations */
	background: url("assets/svg/arrow.svg") #fff no-repeat right center !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/* Better placement regardless of input width */
}

.form-group select {
	border: none;
	padding: 0px 30px 0px 15px;
	border-radius: 25px;
	height: 45px;
	background-color: #f5f8f8;
	color: #1B676B;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: flex;
	background: url("assets/svg/arrow.svg") #f5f8f8 no-repeat right center !important;
	/* !important used for overriding all other customisations */
	background: url("assets/svg/arrow.svg") #f5f8f8 no-repeat right center !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

select::-ms-expand {
	display: none;
}

.form-group select:focus, .inline-group select:focus {
	border: none;
	color: #195659;
	background-color: #f5f8f8;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-group select option, .inline-group select option {
	background-color: #1B676B;
	padding: 0.625rem;
	height: 50px;
	line-height: 50px;
	border-radius: 20px;
	color: #fff;
	font-weight: 500;
	font-family: "Poppins-Regular", sans-serif !important;
}

.form-group select:focus {
	border: 1px solid #195659;
	color: #195659;
	background-color: #F5F8F8;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.inline-group select:focus {
	border: none;
	color: #195659;
	background-color: #F5F8F8;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-group select option:focus, .inline-group select option:focus {
	height: 90px;
	background-color: #55ce63
}

.font-15 {
	padding: 5px 20px;
	font-size: 0.9375rem;
}

.in-grp {
	border-radius: 25px;
	color: #1B676B
}

.newField {
	/* margin-left:0.625rem; */
	border: none;
	width: 100%;
	height: 47px;
	text-align: left;
	padding: 0px 20px;
	border-radius: 25px;
	background-color: #f5f8f8;
	color: #1B676B;
}

.newField span {
	padding-left: 20px;
}

.inline-form label {
	display: block;
}

.inline-form input {
	height: 45px;
	width: 95%;
	padding: 0px 0px;
	display: inline-block;
	border: none;
	background-color: #f5f8f8;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #195659;
}

.inline-form i {
	text-align: center;
	height: 47px;
	width: 5% !important;
	font-size: 1rem;
	line-height: 47px;
	background-color: #f5f8f8;
}

textarea.form-control {
	background-color: #f5f8f8;
	border: none;
	border-radius: 4px;
	resize: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.navbar-nav>li {
	float: left;
}

.form-group {
	margin-bottom: 0.625rem;
}

.display-block {
	display: block;
}

.btn.rounded {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.btn.focus, .btn:focus {
	box-shadow: inherit !important;
}

.list-group-item {
	border: 1px solid #eaeaea;
}

.circle {
	display: inline-block;
	height: 30px;
	width: 30px;
	line-height: 30px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	color: #FFF;
	text-align: center;
}

img.header-icon {
	height: 40px;
	width: 40px;
}

/*----------------- Content -----------------------*/

.main-wrapper {
	height: 0 auto;
}

.page-wrapper {
	left: 0;
	margin-left: 255px;
	height: 100%;
	// padding-top: 20px;
	position: relative;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	overflow-y: auto;
}

.page-wrapper>.content {
	padding: 100px 100px 50px 20px;
}

.page-wrapper>.content-no-padding {
	padding: 100px 20px;
	margin-left: -20px;
}

.inner-page {
	padding: 20px;
}

.clearfix {
	margin: 10px 0px;
	border-bottom: 2px solid #14484B;
}

/*-------------------------------- Bootstrap Card ------------------------------- */

.card-table.card-table-top .card-body {
	height: 175px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.chart-title {
	margin-bottom: 25px;
}

.chart-title h4 {
	display: inline-block;
	font-size: 1.125rem;
	line-height: 20px;
	margin: 0;
	font-weight: 600;
	color: #195659;
}

.chart-title span {
	color: #1B676B;
}

.card-title>a.btn {
	color: #fff;
}

.card-left {
	display: inline-block;
	width: 75%;
	padding: 5px;
}

.card-left h5 {
	font-family: Poppins-Medium;
	color: #9CB1B3;
	font-weight: 550;
}

.card-right {
	display: inline-block;
	margin-left: auto;
	width: 25%;
	padding: 5px;
}

/* ------------------------------- button class -----------------------------*/

.nod-btn {
	height: 60px;
}

.detailBtn {
	border-radius: 25px;
	height: 47px;
	padding: 0px 20px;
	margin-left: 5px;
	background: transparent;
	color: #1B676B;
	font-size: 0.875rem;
	line-height: 23px;
	width: 300px;
}

.detailBtn span {
	margin-left: auto;
	text-align: right;
	padding-left: 30px;
}

.closeBtn {
	color: #195659;
	font-size: 24px;
	cursor: pointer;
	padding-left: 22px;
	margin: 0 auto;
	display: block;
}

.closeBtn i {
	font-weight: 500;
}

.addBtn {
	color: #fff;
	display: none;
}

.myAccordian {
	display: none;
}

.cross-button {
	display: none;
}

.myAccordian input {
	background-color: #E3E9EA;
	border-radius: 26px;
	margin-left: -16px;
	height: 42px;
	padding-bottom: 1px;
}

.card .card-header {
	color: #195659;
}

form-check-input {
	/* margin-top: .3rem; */
	margin-left: 0.625rem;
}

.btn-primary-one {
	border: 3px solid #195659;
	border-radius: 25px;
	padding: 5px 0.625rem;
	min-width: 120px;
	height: 40px;
	margin-right: 5px;
	background: transparent;
	color: #195659;
}

.btn-primary-dark {
	border-radius: 25px;
	/* padding: 5px 0.625rem; */
	min-width: 105px;
	height: 35px;
	margin-right: 5px;
	text-align: center !important;
	color: #fff;
	background: #1B676B;
}

.btn-primary-dark:disabled {
	opacity: 0.4;
}

.btn-primary-one:hover:enabled {
	background: #195659;
	color: #fff;
}

.btn-primary-two {
	border: 2px solid #195659;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	background: transparent;
	color: #1B676B;
    position: relative;
    &__text {
      	position: absolute;
      	left: 3px;
      	bottom: -8px;
      	padding: 0 6px;
      	background: #FFFFFF;
      	border: 2px solid #DCE7E7;
      	border-radius: 8px;
	  	text-transform: lowercase;
		font-size: 13px;
	}
}

.btn-primary-two i {
	font-size: 30px;
	line-height: 55px;
}

.btn-primary-two svg {
	font-size: 30px;
	margin-top: 0.625rem;
}

.btn-primary-rounded {
	border: 1px solid #55ce63;
	padding: 2px;
}

.btn-primary-four {
	border: 1px solid #ffbc35;
	border-radius: 25px;
	width: 100px;
	background: transparent;
	color: #000;
}

.nod-btn:hover, .nod-btn.active.focus, .nod-btn.active:focus, .nod-btn.active:hover, .nod-btn.focus:active, .nod-btn:active:focus, .nod-btn:active:hover, .open>.dropdown-toggle.nod-btn.focus, .open>.dropdown-toggle.nod-btn:focus, .open>.dropdown-toggle.nod-btn:hover {
	background-color: #195659;
	color: #fff;
}

/*-------------------------- Custom CSS for ng-select -----------------------*/

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	color: #fff;
	background-color: #1B676B;
	display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: #1B676B;
	color: #fff;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
	color: #9CB1B3;
	font-size: 0.9375rem;
	line-height: 15px;
	padding: 5px 0.625rem;
}

.ng-select .custom-fix .ng-select-container .ng-value-container .ng-placeholder {
	border-radius: 55px;
	width: 100%;
	height: 47px;
	border: 1px solid #f5f8f8;
	background: #f5f8f8;
}

.ng-select.ng-select-single .ng-select-container {
	color: #1B676B;
	height: 47px;
	border: 4px;
	background-color: transparent;
	/* padding:0px 0px; */
}

.ng-select {
	width: 100%;
	position: relative;
	display: flex;
	box-sizing: border-box;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
	color: #195659 !important;
	/* height:47px; */
}

.ng-dropdown-panel {
	box-sizing: border-box;
	position: absolute;
	opacity: 0.2;
	width: 100%;
	z-index: 9 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	background-color: #fff;
	color: #195659;
	padding: 8px 0.625rem;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	padding-top: 0px !important;
	vertical-align: middle;
}

.ng-select .ng-select-container {
	background-color: transparent;
	color: #195659 !important;
	/* border-radius:px */
	border: none;
	min-height: 36px;
	align-items: center;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	background-color: transparent;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	top: 15px;
	padding-bottom: 5px;
	padding-left: 3px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	border-color: #999 transparent transparent;
	border-style: none;
	border-width: 5px 5px 2.5px;
}

.ng-select .ng-clear-wrapper {
	width: 30px;
	text-align: center;
	color: #1b676b;
	font-weight: 700;
	padding-right: 5px;
}

.ng-select .ng-arrow-wrapper {
	display: none;
}

/*----------------- Custom css Full Calendar -----------------------*/

.fc table {
	width: 100%;
	box-sizing: border-box;
	table-layout: fixed;
	text-align: center;
	border-collapse: separate;
	border-spacing: 10px 0.625rem;
	font-size: 1.2em;
}

.fc-event {
	background: transparent;
	color: #14484B;
	font-weight: 550;
	border: none;
}

.fc-unthemed thead {
	border-color: transparent;
	color: #9CB1B3;
}

.fc th.fc-widget-header {
	border: none;
	background: transparent;
	font-size: 0.875rem;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed td {
	border-color: transparent;
}

td.fc-day {
	background: transparent;
	text-align: center;
	border-radius: 0.625rem;
	border-color: #ccc;
	padding: 20px;
}

.fc th, .fc td {
	border-style: solid;
	border-width: 1px;
	padding: 20px;
	text-align: center;
}

/*-----------------
	8. Login
-----------------------*/

form.form-signin {
	padding: 20px;
	margin-top: 0.625rem;
}

.account-page {
	display: table-row;
	height: 100%;
	padding: 40px 0;
}

.account-title {
	color: #1B676B;
	font-family: Poppins-Regular;
	font-size: 1.125rem;
	line-height: 29px;
	padding: 15px 0px;
	text-align: center;
}

.account-wrapper {
	background: url("assets/svg/login_bg.svg") no-repeat fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-color: #fafafa;
	display: table;
	table-layout: fixed;
	height: 100%;
	padding: 0.625rem;
	width: 100%;
}

.account-center {
	vertical-align: middle;
	padding-top: 5%;
}

.account-box {
	background-color: #fff;
	border: 1px solid #eaeaea;
	margin: 0 auto;
	max-width: 450px;
	border-radius: 0.625rem;
	padding: 0px;
}

.login-logo {
	display: block;
	width: 275px;
	height: 81px;
	margin: 0 auto;
	background: url("assets/images/nod-logo.png") no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: 100%;
	margin-top: 4rem;
	margin-bottom: 0.4rem;
}

.login-text {
	display: block;
	margin: 0 auto;
	padding: 30px 0px;
	text-align: center;
	color: #8AB0B2;
	font-family: Poppins-Medium;
	font-size: 0.875rem;
	line-height: 23px;
}

/*------------ Input -----------------------------*/

input {
	outline: none;
	border: none;
	background: none;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

input:focus:-moz-placeholder {
	color: transparent;
}

input:focus::-moz-placeholder {
	color: transparent;
}

input:focus:-ms-input-placeholder {
	color: transparent;
}

input::-webkit-input-placeholder {
	color: #adadad;
}

input:-moz-placeholder {
	color: #adadad;
}

input::-moz-placeholder {
	color: #adadad;
	font-size: 1rem;
}

input:-ms-input-placeholder {
	color: #adadad;
}

/*---------------------------------------------*/

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover:not([disabled]) {
	cursor: pointer;
}

.wrapper-input {
	width: 100%;
	position: relative;
	border-bottom: 2px solid #E3E9EA;
}

.wrapper-input input[type=text], input[type=password] {
	border: none;
	background-color: #fff;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.input {
	font-family: Poppins-SemiBold;
	font-size: 0.875rem;
	color: #9CB1B3;
	line-height: 1.2;
	display: block;
	width: 100%;
	height: 50px;
	background: transparent;
	padding: 0 5px;
}

/*---------------------------------------------*/

.focus-input {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}

.focus-input::before {
	content: "";
	display: block;
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 0;
	height: 2px;
	transition: all 0.4s;
	background: #E3E9EA;
}

.focus-input::after {
	font-family: Poppins-Medium;
	font-size: 0.875rem;
	color: #9CB1B3;
	line-height: 20px;
	content: attr(data-placeholder);
	display: block;
	width: 100%;
	position: absolute;
	top: 15px;
	left: 0px;
	padding-left: 5px;
	transition: all 0.4s;
}

.input:focus+.focus-input::after {
	top: -20px;
	font-size: 13px;
}

.input:focus+.focus-input::before {
	width: 100%;
}

.has-val.input+.focus-input::after {
	top: -20px;
	font-size: 0.875rem;
}

.has-val.input+.focus-input::before {
	width: 100%;
}

/*-------------------------- Login Button -----------------*/

.login-form-button {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.remember-me {
	padding-top: 30px;
	height: 23px;
	color: #555555;
	font-size: 0.875rem;
	font-weight: 300;
	line-height: 23px;
}

.login-btn {
	font-family: Poppins-Medium;
	font-size: 1rem;
	color: #fff;
	line-height: 1.2;
	display: flex;
	padding: 10px 40px;
	height: 40px;
	background-color: #1B676B;
	border-radius: 25px;
	transition: all 0.4s;
}

.login-btn:disabled {
	background-color: #9CB1B3;
}

.login-btn:hover:not([disabled]) {
	background-color: #333333;
	color: #fff;
}

/*----------------- Sidebar -----------------------*/

.sidebar {
	font-family: Poppins-Regular;
	top: 0;
	width: 255px;
	background-color: #1B676B;
	bottom: 0;
	z-index: 1004;
	margin-top: 0px;
	position: fixed;
	left: 0;
	transition: all 0.2s ease-in-out;
}

.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.sidebar-inner {
	padding-top: 120px;
	z-index: 999;
	transition: all 0.2s ease-in-out 0s;
	background-color: #1B676B;
	/* padding-bottom: 85px; */
	/* border-radius: 25px; */
}

.sidebar-menu ul {
	font-size: 0.875rem;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.sidebar-menu li a {
	text-decoration: none;
	color: #fff;
	display: block;
	font-size: 0.9375rem;
	height: auto;
	padding: 10px 20px;
	line-height: 25px;
}

.sidebar-menu li a:hover, .sidebar-menu li.active {
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
	background-color: #195659;
}

.sidebar-menu li a:hover i, .sidebar-menu li a:hover span.circle, .sidebar-menu li.active a i {
	color: #BEF202;
}

.sidebar-menu li.active a {
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	color: #fff;
	background-color: #195659;
}

.sidebar-menu li.active a span.circle, .sidebar-menu li a:hover span.circle {
	font-family: Poppins-Bold;
	background-color: #BEF202;
	color: #195659;
	font-weight: 600;
}

.sidebar .sidebar-menu>ul>li>a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 0.625rem;
	white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
	color: #fff;
	margin-left: auto;
}

.sidebar-menu>ul>li {
	position: relative;
	max-width: 245px;
	margin: 5px 0;
}

.sidebar-menu>ul>li>a {
	padding: 15px 20px;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	position: relative;
	transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li a i {
	align-items: center;
	display: inline-flex;
	font-size: 1.25rem;
	min-height: 24px;
	line-height: 18px;
	width: 25px;
	transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
	font-size: 1rem;
	text-align: right;
	width: auto;
}

/* details */

.tab-content {
	padding: 20px 10px 20px 0px;
}

table.detail-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	border-spacing: 1em 1em;
}

table.detail-table tr td:first-child {
	color: #1B676B;
	font-size: 0.875rem;
	line-height: 24px;
	vertical-align: top;
	/* white-space: nowrap; */
}

table.detail-table tr td:last-child {
	color: #78898B;
	font-size: 0.875rem;
	line-height: 24px;
	vertical-align: top;
	white-space: normal;
}

table.detail-table>tbody>tr {
	height: 30px;
}

.break {
	margin-top: 0.625rem;
	height: 4px;
	padding: 1px;
	margin-left: -27px;
	margin-right: -27px;
	background-color: #f5f8f8;
}

.detail-nav {
	float: right;
}

/*-----------------  Header -----------------------*/

.header {
	background-color: #fff;
	width: 96%;
	height: 90px;
	border-radius: 50px;
	box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
	display: block;
	z-index: 1039;
	position: fixed;
	top: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.header-inner {
	display: flex;
	/* margin-bottom: 0.625rem; */
}

.header-left {
	/* padding: 20px 5px 20px 30px; */
	display: inline-flex;
	white-space: nowrap;
}

.header-inner .header-left img {
	padding: 10px 20px 10px 0px;
}

.header-center {
	width: 100%;
	padding: 20px 5px;
	display: inline-flex;
}

.header-right {
	padding: 10px 0px;
	margin-left: auto;
	min-width: 80px;
	display: inline-flex;
}

.title {
	/* height: 40px; */
	color: #195659;
	font-family: Poppins-Regular;
	font-size: 24px;
	font-weight: 600;
	line-height: 90px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/* margin-left: 22px; */
}

.full-title {
	color: #195659;
	font-family: Poppins-Regular;
	font-size: 24px;
	font-weight: 600;
	line-height: 90px;
	padding-left: 22px;
}

.title-breadcrum {
	color: #195659;
	font-family: Poppins-Regular;
	font-size: 24px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	margin-top: 20px;
	padding-left: 22px;
}

.subtitle {
	margin-top: -5px;
	display: block;
	color: #909E9F;
	font-weight: 550;
	font-size: 0.875rem;
}

.logo {
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: center;
}

.logo span {
	color: #fff;
	font-size: 1.125rem;
	font-weight: 500;
	margin-left: 0.625rem;
}

.header .navbar-nav .badge {
	position: absolute;
	right: 7px;
	top: 4px;
}

.header .dropdown-menu>li>a {
	position: relative;
}

.header .dropdown-toggle:after {
	display: none;
}

.header .has-arrow .dropdown-toggle:after {
	display: inline-block;
}

.page-title-box {
	border-radius: 0;
	height: 50px;
	margin-bottom: 0;
	padding: 14px 20px;
}

.page-title-box h3 {
	color: #fff;
	font-size: 1.125rem;
	font-weight: 500;
	margin: 0;
}

.user-menu {
	margin: 0;
	position: relative;
	z-index: 99;
}

.user-menu.nav>li>a {
	color: #fff;
	font-size: 1rem;
	line-height: 50px;
	padding: 0 15px;
	height: 50px;
}

.user-menu.nav>li>a:hover, .user-menu.nav>li>a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

.user-img {
	display: inline-block;
	position: relative;
}

.header .user-img img {
	width: 24px;
}

.user-img .status {
	border: 2px solid #fff;
	bottom: 0;
	height: 0.625rem;
	position: absolute;
	right: 0;
	width: 0.625rem;
}

.user-menu .user-img .status {
	bottom: 0.625rem;
}

.user-menu .dropdown-menu {
	min-width: 136px;
}

.user-menu .bg-primary {
	background-color: #1B676B !important;
}

.status {
	background-color: #ccc;
	border-radius: 50%;
	display: inline-block;
	height: 0.625rem;
	width: 0.625rem;
}

.status.online {
	background-color: #55ce63;
}

.status.offline {
	background-color: #f62d51;
}

.status.away {
	background-color: #faa937;
}

.mobile_btn {
	display: none;
}

.saveBtn {
	border: 2px solid #195659;
	height: 70px;
	color: #1B676B;
	font-size: 0.875rem;
	text-align: center !important;
	border-radius: 40px;
	min-width: 120px !important;
}

.dispButton {
	display: none;
}

/* modal box style */

.modal-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #fff;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	color: #1b676b;
}

.subTitle {
	/* margin: 30px 0px; */
	height: 40px;
	width: 100%;
	color: #9CB1B3;
	font-size: 0.875rem;
	line-height: 20px;
	border-bottom: 2px solid #9CB1B3;
}

.subTitle input {
	width: 100%;
	padding: 0px 5px;
}

.modal-btn {
	color: #1B676B;
}

.modal__heading {
	color: #1b676b;
	font-size: large;
	padding-left: 20px;
  }
  
  .register-box {
	background-color: #fff;
	border: 1px solid #dde4e5;
	max-width: 60%;
	margin: 0 auto;
	border-radius: 10px;
	padding: 0px;
  }
  
  .signup-form-header {
	text-align: center;
	margin: 0 10px;
	padding: 0 10px;
  }
  
  .signup-form-header h1 {
	font-size: 1.875rem;
  }
  
  .vl {
	border-left: 6px solid green;
	height: 500px;
	position: absolute;
	left: 50%;
	margin-left: -3px;
	top: 0;
  }
  
  .border1 {
	border-right: 1px solid #bdcbcc;
	margin: 5px 0px 0px;
  }
  
//   input[type="text"] {
// 	width: 100%;
// 	height: 36px;
// 	background: transparent;
// 	// border-bottom: 2px solid #bdcbcc;
//   }
  
  .model__input {
	width: 100%;
	height: 35px;
	background: transparent;
	border-bottom: 2px solid #bdcbcc;
  }
  
  .model-body-border {
	padding-bottom: 10px;
	border-bottom: 1px solid #e9ecef;
  }
  
  select {
	margin: 5px;
  }

// 
// .close-moodal {
// 	float: right;
// 	display: inline;
// 	text-align: right;
// 	cursor: pointer;
// 	padding-right: 1px;
// 	padding-top: 0;
//   }
  
//   .close-moodal i {
// 	padding: 5px 5px 5px 5px;
//   }




/* ------------------------ icons within header --------------------*/

.filter-row {
	border-radius: 0;
	display: flex;
	min-width: 70%;
	/* width:100%; */
	/*
	justify-content: center;
	justify-items: center;
	vertical-align: middle; */
}

.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 0.75rem;
	z-index: 1;
}

.action-label .label {
	display: inline-block;
	min-width: 85px;
	padding: 0.5em 0.6em;
}

.action-label i {
	margin-right: 3px;
}

.action-label .dropdown-menu>li>a {
	padding: 3px 0.625rem;
}

input.search-input {
	width: 100%;
	padding: 0px 5px 10px 15px;
	font-size: 1rem;
	line-height: 16px;
	height: 35px;
	color: #195659;
}

select.search-input {
	background-color: transparent;
	padding: 0px 5px 10px 15px;
	font-size: 1rem;
	line-height: 16px;
	border: none;
	height: 35px;
	width: 10.625rem;
	color: #195659;
}

select.search-input::-ms-expand {
	display: none;
}

select>option {
	margin-top: 0.625rem;
	color: #fff;
	background-color: #1B676B;
	position: relative;
}

.searching-box {
	display: flex;
	width: 100%;
	background-color: #f5f8f8;
}

.searching-box svg {
	/* margin-top: 16px; */
	height: 45px;
	margin-left: 5px;
}

/*-----------------  Search  -----------------------*/

.search-data {
	width: 100%;
	display: flex;
	background-color: #1B676B;
	color: #fff;
	height: 50px;
	padding-left: 0.625rem;
	line-height: 50px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-top: -20px;
}

.add-patient {
	border-top: 2px solid #1B676B;
	height: 50px;
	line-height: 50px;
	width: 100%;
	box-shadow: #14484B;
	background-color: #1B676B;
	color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

/*-----------------  Table -----------------------*/

table.nod-table {
	color: #78898B;
	width: 99.5%;
	/* border-spacing: 0em 1em;
		border-collapse: separate; */
}

td {
	word-wrap: break-word;
}

thead {
	position: sticky;
	top: 0;
}

table.nod-table tbody tr td:first-child svg {
	color: #1B676B;
	float: right;
	cursor: pointer;
	font-size: 25px;
	margin-right: -20px;
	text-align: right;
	/* margin-top: -8px; */
}

table.nod-table tbody tr td:nth-child(2) svg {
	margin-top: 0.625rem;
}

table.nod-table tbody tr td svg {
	color: #1B676B;
	cursor: pointer;
	font-size: 1.125rem;
	text-align: left;
}

table.nod-table tbody tr td svg:hover {
	color: #BEF202;
}

table.nod-table tbody tr.current td.border-cell, table.nod-table tbody tr.activated td.border-cell {
	border-top: none;
	border-right: 2px solid #1B676B;
	width: 70px;
	padding-top: 0.625rem;
	vertical-align: top;
	/* border-bottom: 20px solid #fff; */
}

table.nod-table tbody tr td.border-cell {
	border-right: 2px solid #1B676B;
}

.action {
	width: 25%;
	text-align: right;
	padding: 0px 20px;
	background: none;
}

table.nod-table tbody tr {
	background: transparent;
	padding: 0.625rem;
	height: 75px;
	border-collapse: separate;
	cursor: default;
	/* border-bottom:15px solid transparent; */
}

table.nod-table>thead>tr {
	color: #9CB1B3;
	cursor: default;
	/* border-bottom:2px solid #BEF202; */
}

table.nod-table>thead>tr>th {
	color: #9CB1B3;
	font-size: 1rem;
	line-height: 16px;
}

table.nod-table>tbody>tr>td, table.nod-table>table>tbody>tr>th, table.nod-table>table>tfoot>tr>td, table.nod-table>table>tfoot>tr>th, table.nod-table>thead>tr>td {
	padding: 0px 0.625rem;
	cursor: default;
}

table.patient-table {
	color: #78898B;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 4px;
	white-space: nowrap;
}

table.patient-table>thead>tr {
	padding: 0.625rem;
	border: none;
	cursor: default;
}

table.patient-table>tbody>tr {
	background-color: #fff;
	height: 65px;
}

table.patient-table>tbody>tr>td:first-child {
	font-size: 1rem;
}

table.patient-table>tbody>tr>td, table.patient-table>table>tbody>tr>th, table.patient-table>table>tfoot>tr>td, table.patient-table>table>tfoot>tr>th, table.patient-table>thead>tr>td {
	padding: 10px 30px;
	vertical-align: middle;
	cursor: default;
	/* cursor: pointer; */
}

table.patient-table tbody tr td:first-child {
	cursor: pointer;
}

/* bs-datepicker-container .bs-datepicker-container bs-days-calendar-view{
	width:500px;
	overflow:auto;
} */

table.nod-table thead>tr>th {
	font-weight: 500;
	font-family: Poppins-Medium;
	padding: 20px 0.625rem;
	font-size: 0.875rem;
	vertical-align: middle;
	cursor: default;
	color: #9CB1B3;
}

table.patient-table thead>tr>th {
	font-weight: 500;
	font-family: Poppins-Medium;
	padding: 10px 30px;
	font-size: 0.875rem;
	vertical-align: middle;
	cursor: default;
	color: #9CB1B3;
}

.clearfix {
	margin-bottom: 20px;
}

/* .patient {
	min-width: 150px;
} */

/* Appointment css */

.nav-tabs>li>a {
	margin-right: 0;
	color: #195659;
	border-radius: 0;
}

.nav-tabs>li>a:hover, .nav-tabs>li>a:focus {
	border-color: transparent;
	color: #14484B;
}

.nav-tabs.nav-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
	color: #195659;
}

.nav-tabs.nav-tabs-solid>li>a.active, .nav-tabs.nav-tabs-solid>li>a.active:hover, .nav-tabs.nav-tabs-solid>li>a.active:focus {
	background-color: #1B676B;
	border-color: #195659;
	color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
	padding: 10px 20px;
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a span {
	margin-left: auto;
	padding-left: 30px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active, .nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover, .nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
	border-radius: 50px;
}

.nav-tabs-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover, .nav-tabs-justified>li>a:focus {
	border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

p.speciality {
	color: #909E9F;
	margin-bottom: 0rem;
}

.backBtn{
	display: none;
}

.detail-table{
	display: none;
}
/*-----------------
	42. Responsive
-----------------------*/

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) and (max-width: 1440px) {
	.header-left {
		/* line-height: 50px; */
		display: inline-flex;
	}
	.header-left .title {
		/* min-width: 150px;
		max-width: 200px; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.nav-tab {
		justify-content: flex-end!important;
	}
	.page-wrapper>.content {
		padding: 100px 10px 20px 0px;
	}
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
	.header-left .title {
		white-space: nowrap;
		overflow: hidden;
		line-height: 90px;
		text-overflow: ellipsis;
	}
	.sidebar{
		width: 225px;
	}
	.page-wrapper{
		margin-left: 255px;
	}

}


.error {
	background-color: red;
	position: fixed;
	color: #fff;
	z-index: 1111;
	font-weight: 600;
	top: 5%;
	right: 5%;
}

.success {
	background-color: #14484B;
	position: fixed;
	color: #fff;
	z-index: 1111;
	font-weight: 600;
	top: 5%;
	right: 5%;
}

.panel {
	width: 100%;
	border: none;
	border-radius: 5px;
	box-shadow: none;
	margin-bottom: 0.625rem;
}

.panel-body {
	font-size: 0.9375rem;
	color: #195659;
	margin-right: 10%;
	line-height: 28px;
	letter-spacing: 1px;
	border-top: none;
	border-radius: 5px;
}

a.nav-link:hover {
	background-color: #fff;
}

/* }
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	border-color: #fff;
} */

a.nav-link .active {
	border-bottom: 5px solid #195659;
}

/* Date Picker */

.bs-datepicker-body table td.week span {
	color: #1B676B !important;
}

.bs-datepicker-body table td span.selected, .bs-datepicker-head, .bs-datepicker-head, .bs-datepicker button:active, .bs-datepicker-body table td.selected span, .bs-datepicker-body table td span[class*="select-"]:after, .bs-datepicker-body table td[class*="select-"] span:after, .bs-datepicker-body table td.active-week span:hover {
	background-color: #1B676B !important;
}

.swal-text {
	text-align: center;
}

.swal-button {
	background: transparent;
	color: #fff;
	border-radius: 0px;
	font-weight: 600;
	padding: 15px;
	font-size: 1rem;
	width: 200px;
}

.swal-button:not([disabled]):hover {
	color: #fff;
	background-color: #195659;
	/* border:1px solid #14484B; */
}

.swalSuccess {
	background-color: green;
}

.swalError {
	background-color: red;
}

.swalWarning {
	background-color: yellow;
}

.swal-footer {
	text-align: center;
	padding-top: 13px;
	margin-top: 13px;
	padding: 13px 16px;
	/* border-radius: inherit; */
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.detail-card {
	margin-top: 1%;
	margin-bottom: 1%;
	margin-left: 2%;
	margin-right: 3%;
}

.second-div {
	color: #909E9F;
}

/* Spinner */

.mySpinner {
	display: block;
	margin: 0 auto;
	justify-items: center;
}

.middle-spinner {
	width: 100%;
	display: flex;
}

/** Appointment Model **/

.spaciality {
	/* margin-left: -7px; */
	border: solid 3px #000;
}

.spacialityTitle {
	padding: 0.625rem;
	/* border: 1px solid; */
	background-color: #f5f8f8;
	color: #1B676B;
}

.selectPatient {
	margin-left: 7px;
	border: solid 3px #000
}

.spaciality-form {
	background-color: #1B676B;
	padding: 10px
}

.spaciality-form input {
	border-bottom: 2px solid black;
	width: 100%;
	color: #fff;
	font-size: large;
}

.spaciality-form-item {
	padding-top: 15px;
	color: #ffffff;
	font-size: large;
	cursor: pointer;
}

.spaciality-form-item .col-md-4 {
	font-weight: 400;
	font-size: medium;
}

.spaciality-form-item .col-md-3 {
	font-weight: 400;
	font-size: medium;
}

.spaciality-form1 {
	background-color: rgb(247, 49, 0);
	padding: 0.625rem;
	border: 2px solid #1B676B
}

.spaciality-form1 input {
	border-bottom: 2px solid black;
	width: 100%;
	font-size: large;
}

.spaciality-form1-item {
	padding-top: 15px;
	color: #ffffff;
	font-size: large;
	cursor: pointer;
}

.spaciality-form1-item .col-md-4 {
	font-weight: 400;
	font-size: medium;
}

.spaciality-form1-item .col-md-3 {
	font-weight: 400;
	font-size: medium;
}

.provider-title {
	padding-top: 15px;
	padding-bottom: 15px;
	color: #ffffff;
	font-size: large;
}

.disableDiv {
	pointer-events: none;
}

.enableDiv {
	cursor: pointer;
}

.mat-snack-bar-container {
	color: #fff;
	font-weight: 600;
	font-size: 1.125rem;
}
.mat-simple-snackbar{
	word-break: break-word;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #195659 !important;
}

mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: #408185 !important
}

img.rounded-circle {
	width: 100px;
	height: 100px;
	margin-top: 0px;
}

.spinner-border {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: text-bottom;
	border: .2em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite;
}

/* Cursor Pointer */

.c-p {
	cursor: pointer !important;
}

img.show-image {
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	margin: 0 auto;
	display: block;
}

.bs-datepicker-body table.days span {
	width: 36px;
	height: 36px;
	line-height: 36px;
}

td span.in-range, td span.in-range:hover {
	background: #bef202;
}

td span.in-range {
	background: #bef202;
}

.bs-datepicker-body table td span {
	border-radius: 0px;
}

.bs-datepicker-body table td.week span {
	display: none;
}

.no-data {
	top: 50%;
	position: fixed;
	bottom: 50%;
	left: 0;
	right: 0;
	font-size: xx-large;
	font-weight: 500;
	text-align: center;
}

.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
	border-radius: 50%;
	background: #bef202;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
	opacity: 0.03 !important;
	background-color: #195659!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: #195659;
}

.mat-tooltip {
	font-size: 0.75rem;
	background-color: #195659!important;
}

.exaggerated-tooltip {
	font-size: 0.75rem;
	color: #fff;
}

/* ::ng-deep  */

.text-underline {
	text-decoration: underline;
	color: #a8bbbd;
	cursor: pointer;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #14484B;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #14484B;
}

.delete-confirm-dialog-mat-dialog-container mat-dialog-container{
    background-color: #FFF;
    width: 675px  !important;
    height: 200px !important ;
    border-radius: 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative !important;
}

.border-filter {
	border: 1px solid #1b676b;
	border-radius: 25px;
	background: #fff;
	margin-left: 0.625rem;
	height: 45px;
}

.border-filter-order {
	border-radius: 25px;
	background: #fff;
	margin-left: 0.625rem;
	height: 40px;
}

.border-filter>input:placeholder-shown {
	color: #1b676b;
}

.border-filter-order>input:placeholder-shown {
	color: #1b676b;
}

.input-group-text {
	background-color: transparent;
	border: 0px;
	color: #1b676b;
}

.font-14 {
	font-size: 0.875rem;
}

.modal-backdrop {
	z-index: 1040;
}

.modal {
	z-index: 1041;
}

table.detail-table td ul li {
	list-style: none;
	list-style-type: none;
}

table.detail-table td ul {
	padding-left: 0;
}

.f-17 {
	font-size: 17px;
}

.f-w-600 {
	font-weight: 600;
}

.required-field:after {
	content: "*";
	color: red;
	padding: 5px;
}

@-moz-document url-prefix() {
	.closeBtn {
		padding-top: 30px;
	}
}

.tooltip-common {
	background-color: #FFF !important;
	border: none !important;
	text-align: center;
	font-size: 17px !important;
	font-weight: 400 !important;
	border-radius: 25px !important;
	right: 0;
	color: #4e5a5c !important;
}

.mat-calendar-body-selected {
	background: #1B676B;
}
.mat-calendar-body-disabled>.mat-calendar-body-selected{
	background: #1B676B !important;
	opacity: .5;
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected){
	background: #e3f0f0;
  border-color: #e3f0f0;
}

.mat-calendar-body-cell-content {
	width: 60% !important;
	height: 60% !important;
}

mat-calendar.mat-calendar {
	margin-top: -30px;
}

td.mat-calendar-body-label:first-child {
	visibility: hidden;
}

.mat-calendar-table-header th {
	font-size: 0.875rem;
}

.password-eye {
	position: relative;
	left: 95%;
	cursor: pointer;
	bottom: 35px;
}

.cdk-overlay-container {
	z-index: 1090 !important;
}

.at-least-two-char, .at-least-three-char {
	display: block;
	padding: 10px 0px;
	color: #28a745;
}

.at-least-three-char::after {
	content: "Search term must be at least three characters"
}

.at-least-two-char::after {
	content: "Search term must be at least two characters"
}

.mat-dialog-content {
	max-width: 870px;
	white-space: pre-line;
}

.not-sync-presc-error, .not-sync-status {
	background: rgba(234, 100, 35, 1);
	border-radius: 20px;
	padding: 0 5px;
	font-size: 12px;
	color: white;
	white-space: nowrap;
}

.not-sync-presc-error::after, .not-sync-status::after {
	content: "Not Sync";
}

.sync-status {
	padding: 0 7px;
	background-color: #bdec23;
	border-radius: 20px;
	font-size: 12px;
	white-space: nowrap;
	color: #1b666b
}

.sync-status::after {
	content: "Sync";
}

.delete-athena-status {
	background: rgba(234, 100, 35, 1);
	border-radius: 20px;
	padding: 0 5px;
	font-size: 12px;
	color: white;
	white-space: nowrap;
}

.delete-athena-status::after {
	content: "Deleted in Athena";
}

.ehr-error {
	color: rgba(234, 100, 35, 1);
	font-weight: 600;
	font-size: 14px;
	margin-top: 5px;
}

.btn-primary-dark {
	border-radius: 25px;
	padding: 10px 35px;
	margin-right: 10px;
	background: #FFF;	
	color: #1b676b;
	border: 2px solid #1b676b;
}

.btn-primary-dark:hover{	
	background-color: #BEF202;
	color: #1b676b;
}

.btn-cancel{
	border-radius: 25px;
  padding: 10px 35px;
  margin-right: 5px;
  background: #FFF;
	color: #78898B;
	border: 2px solid #E3F0F0;
}

.btn-cancel:hover{
	border: 2px solid #1b676b;
	background-color: #E3F0F0;
	color: #1B676B;
}

.nod-dark-color{
  color: #14484B;
}

.nod-gray-one{
	color: #9CB1B3 !important;
}

.nod-gray-two{
	color: #78898B !important;
}

.font-12{
  font-size: 12px !important;
}

.profile-round-image{
  height: 100px;
  width: 100px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
}
.profile-round-image img{
  border-style: none;
  width: 100%;
  height: auto;
}

.font-16{
	font-size: 16px;
}

.hide-btn-on-mobile{
	display: block;
}
.mobileView {
	display: none;
}
.filtersMobile {
  display: none;
}

button.cancel-modal-btn {
    padding: 5px 30px;
    border: 5px solid #BEF202;
    background-color: #fff;
    border-radius: 25px;
    font-weight: 600;
    color: #1b676b;
    transition: all .2s;
 }

 button.cancel-modal-btn:hover{
    background-color: #BEF202;
    border: 5px solid #1b676b;
 }
 
 button.delete-modal-btn {
    padding: 3px 20px;
    background-color: #BEF202;
    border-radius: 25px;
    font-weight: 600;
    color: #1b676b;
    border: 5px solid #BEF202;
    transition: all .2s; 
 }

 button.delete-modal-btn:hover{
    border: 5px solid #1b676b;
 }

.btn-order:hover,
.btn-order:visited{
	background-color: #14484B;
}
.spinner-div{
	padding: 0 20px;
	align-items: center;
	display: inline-flex;
}
.btn-primary-dark-order{
	height: 38px;
	line-height: 1;
	margin-left: 5px;
}
.facility-form-content {
    border: 2px solid white;
    border-radius: 10px;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1.175rem;
}
.static-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 15%;
    position: fixed;
    right: 1%;
    margin-top: 1rem;
}
.static-buttons span{
    color: #637779;
    font-size: 12px;
    width: 95%;
}
.disabled-label {
	color: #1b5c5f80;
}
.disabled-bg-color {
	background: #dddddd !important;
}

mat-timepicker-dialog .mat-dialog-content {
	background: white;
}
mat-timepicker-dialog .mat-dialog-actions {
	background: white;
	margin-left: -24px !important;
	margin-right: -24px !important;
}
mat-timepicker-dialog .mat-button.mat-primary {
	color: #1b5c5f;
}
mat-timepicker-dialog .mat-primary {
	color: #1b5c5f;
}
mat-timepicker-dialog .mat-fab.mat-primary,
  .mat-flat-button.mat-primary,
  .mat-mini-fab.mat-primary,
  .mat-raised-button.mat-primary {
	background: #1b5c5f;
}
mat-timepicker-dialog .mat-toolbar.mat-primary {
	background: #1b5c5f;
}
mat-timepicker-dialog .header.mat-toolbar {
	position: relative;
	border-radius: 0px;
	top: 0px;
	background: #1b5c5f;
}
mat-timepicker-dialog .mat-toolbar.mat-primary {
	background: #1b5c5f;
  }
  .mat-chip-list-wrapper input::placeholder {
	color: #9cb1b3;
	font-size: 14px;
  }

/* 
	Below this width apply following CSS
 */

 @media (min-width: 1024px) and (max-width: 1499px) {
    .facility-container{
        width: 70%;
    }
    .static-buttons{
        width:15%;
        right: 1%;
    }
}

@media (max-width: 1023px) {
    .tool-wrapper .tooltip-right {
      min-width: 350px;
    }
    .connection-box {
      width: 80vw;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .facility-container {
      width: 70%;
    }
}

 @media only screen and (max-width: 768px) {
	.btn-primary-two {
		border: 2px solid #BEF202;
		color: #BEF202 !important;
		width: 40px;
		height: 40px;
		padding: 0;
    font-size: 2rem;
    line-height: 1.2;
	}
	.header-right {
		padding: 8px 10px;
    display: inline-flex;
	}
	.btn-primary-two i {
		font-size: 20px;
		line-height: 30px;
		font-weight: 900;
		color: #BEF202;
	}

	.closeBtn {
		display: none;
	}

	/* .header {
		line-height: 50px;
	} */

	.btn-primary-two svg{
		font-size: 16px;
    margin-top: -0.3rem !important;
	}

	.svg-inline--fa{
		vertical-align: inherit;
	}
	.hide-btn-on-mobile{
		display: none;
	}
	.mobileView {
    display: block;
}
.mr-auto>li {
	padding: 10px 30px;
}
.mr-auto>li>a.active {
	color: #195659;
}
nav.custom-bg {
	background: #fff;
	box-shadow: 0 8px 6px -6px #ccc;
	margin-bottom: 10px;
}
a.custom-link {
	color: #1b676b;
	font-size: 1.0625rem;
	padding-left: 10px;
	font-weight: 600;
}
.filtersMobile{
	display: block;
}
#collapseTwo{
	background: #ffff;
}

.panel-heading h4 {
	margin: 0%;
}

.header-right {
	display: flex;
	text-align: right;
	padding: 0;
}

#accordion .panel-body {
	padding: 10px 10px;
	box-shadow: 10px 10px 5px #88888824;
}

.borderToSearch {
	border: 1px solid #1B676B;
	border-radius: 25px;
}

.borderInput input {
	border: 1px solid #1B676B;
}

.ml-auto {
	width: 100%;
}
.patient-table{
	display: none;
}

table.patient-table-mobile tbody{
	background-color: #FFFFFF;
	border-bottom: 25px solid #f5f8f8;
}
.detail-table{
	display: table;
}
table.detail-table {
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #FFFFFF;
}
table.detail-table tbody{
	border-bottom: 25px solid #f5f8f8;
}
table.detail-table td{
	padding: 10px;
}
.backBtn{
	color: #bdec23;
	font-size: 18px;
	font-weight: bolder;
	text-align: right;
	margin-left: 10px;
	display: inline-block;
}
#accordion .panel-body input{
	padding-left: 10px;
	font-size: .85rem;
}
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
	.page-wrapper>.content {
		padding: 20px;
	}
	.page-wrapper>.content-no-padding {
		margin-left: 0px;
	}
	.sidebar {
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		transition: all 0.4s ease;
		margin-left: -225px;
		width: 225px;
		z-index: 1004;
	}
	.sidebar.opened {
		margin-left: 0;
		transition: all 0.4s ease;
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		height: 100%;
	}
	.header {
		height: 60px;
		display: block;
		background: #1B676B;
		position: fixed;
		top: 0;
		border-radius: 0px;
		z-index: 1010;
		width: 100%;
		margin: 0 auto;
	}
	.data-wrapper {
		display: none;
	}
	.header-left {
		padding: 0px 20px;
	}
	.header-left .title {
		font-size: 1.25rem;
		min-width: 50vh;
		line-height: 60px;
		color: #fff;
		padding-left: 0.625rem;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.full-title {
		color: #fff;
		line-height: 60px;
	}
	.title-breadcrum {
		margin-top: 0px;
		color: #fff;
	}
	.btn-primary-one {
		margin-top: 0.625rem;
	}
	.header-center {
		display: none;
	}
	.tab-content {
		padding: 0.625rem;
	}
	/* Responsive buttons */
	.addBtn {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		padding: 12px;
		margin-left: auto;
		color: #fff;
		position: fixed;
		right: 5px;
		top: 0.625rem;
	}
	.myAccordian {
		display: block;
		color: #1B676B;
		background: #fff;
		/* height: 50px; */
	}
	.cross-button {
		display: block;
		position: absolute;
		left: 21px;
		font-size: 45px;
		color: #ffffff;
	}
	.main-wrapper {
		height: 100%;
		width: 100%;
	}
	.sidebar-inner {
		padding-top: 80px;
		bottom: 0;
		height: 100vh;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	a.mobile_btn:hover {
		color: #fff;
	}
	a.mobile_btn:active {
		color: #BEF202;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		height: 40px;
	}
	/* .header-right {
		padding: 2px 10px 2px 10px;
	} */
	a.mobile_btn {
		color: #fff;
	}
	.modal-body.card-box {
		background-color: #fff;
		border: none;
		border-radius: inherit;
		box-shadow: unset;
		margin-bottom: 0;
		padding: 15px;
	}
	.card-title {
		font-size: 1.12em;
	}
	.table-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.week-calendar {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.page-wrapper>.content, .page-wrapper>.content-no-padding {
		padding: 20px;
	}
	.closeBtn {
		margin-right: auto;
		color: #fff;
		position: fixed;
		right: 0.625rem;
		line-height: 60px;
		padding: 20px 0.625rem;
	}
	.addBtn {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		padding: 12px;
		margin-left: auto;
		color: #fff;
		position: fixed;
		right: 15px;
		top: 0.625rem;
	}
	.myAccordian {
		display: block;
		color: #f5f8f8
	}
	.cross-button {
		display: none;
	}
	.main-wrapper {
		height: 100%;
		width: 100%;
	}
	.sidebar-inner {
		padding-top: 80px;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		line-height: 40px;
	}
	/* .header-right {
		display: none;
	} */
	.panel {
		width: 100%;
	}
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	.sidebar-menu li.active a {
		color: #fff;
		background-color: #195659;
		margin-right: 20px;
	}
	.mobile_btn {
		display: block;
	}
	.page-title-box {
		display: none;
	}
	.main-wrapper {
		width: 100%;
		height: 100%;
	}
	.page-wrapper {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		padding-top: 70px;
	}
	.slide-nav .sidebar {
		margin-left: 0;
	}
	a.mobile_btn {
		color: #fff;
		font-size: 25px;
		height: 60px;
		line-height: 25px;
		padding: 15px 0.625rem;
		margin-right: 0px;
		position: absolute;
		left: 0;
		width: 60px;
		z-index: 10;
	}
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	#toggle_btn {
		display: none;
	}
	.page-wrapper {
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
 .filtersMobile{
	display: block;
	}
	.btn-primary-two {
		border: 2px solid #BEF202;
		color: #BEF202 !important;
		width: 40px;
		height: 40px;
		padding: 0;
    font-size: 2rem;
    line-height: 1.2;
	}
	.header-right {
		padding: 5px 10px;
    display: inline-flex;
	}
	.btn-primary-two i {
		font-size: 20px;
		line-height: 30px;
		font-weight: 900;
		color: #BEF202;
	}
	.btn-primary-two svg {
    font-size: 16px;
		margin-bottom: 0.3rem;
	}
	.detail-table{
		display: table;
	}
	.mobile-filter-header{
		height: 85px;
    display: block;
    background: #f5f8f8;
    position: fixed;
    top: 60px;
    border-radius: 0px;
    z-index: 995;
    width: 100%;
		margin: 0 auto;
		padding-top: 10px;
	}	
	nav.custom-bg {
		background: #fff;
		box-shadow: 0 8px 6px -6px #ccc;
		margin-bottom: 10px;
	}
	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs.nav-justified>li>a.active, .nav-tabs.nav-justified>li>a.active:hover, .nav-tabs.nav-justified>li>a.active:focus {
		border-color: transparent transparent transparent #1B676B;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs>li>a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs>li>a:hover, .nav-tabs>li>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs>li>a.active, .nav-tabs>li>a.active:hover, .nav-tabs>li>a.active:focus {
		background-color: #f5f5f5;
		border-color: transparent transparent transparent #1B676B;
		border-left-width: 2px;
	}
	.nav-tabs>li.open:not(.active)>a, .nav-tabs>li.open:not(.active)>a:hover, .nav-tabs>li.open:not(.active)>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs.nav-tabs-solid>li>a {
		border-left-width: 0!important;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified>li>a.active, .nav-tabs-justified>li>a.active:hover, .nav-tabs-justified>li>a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #1B676B;
	}
	.mr-auto>li {
    padding: 10px 30px;
	}
.backBtn{
	color: #bdec23;
	font-size: 18px;
	font-weight: bolder;
	text-align: right;
	margin-left: 10px;
	vertical-align: middle;
  height: 100%;
}
.mat-snack-bar-container{
	margin-right: 3rem !important;
}
.selection-group {
	padding-left: 1px;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
	.page-wrapper>.content {
		padding: 20px;
	}
	.page-wrapper>.content-no-padding {
		margin-left: 0px;
	}
	.sidebar {
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		transition: all 0.4s ease;
		margin-left: -225px;
		width: 225px;
	}
	.sidebar.opened {
		margin-left: 0;
		transition: all 0.4s ease;
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		height: 100%;
	}
	.header {
		height: 60px;
		line-height: 60px;
		display: block;
		background: #1B676B;
		position: fixed;
		top: 0;
		border-radius: 0px;
		z-index: 998;
		width: 100%;
		margin: 0 auto;
	}
	.data-wrapper {
		display: none;
	}
	.header-left {
		padding: 0px 20px;
	}
	.header-left .title {
		font-size: 1.25rem;
		line-height: 60px;
		color: #fff;
		padding-left: 0.625rem;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.full-title {
		color: #fff;
		line-height: 60px;
	}
	.title-breadcrum {
		color: #fff;
		margin-top: 0px;
	}
	.btn-primary-one {
		margin-top: 0.625rem;
	}
	.header-center {
		display: none;
	}
	.tab-content {
		padding: 0.625rem;
	}
	/* Responsive buttons */
	.addBtn {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		padding: 12px;
		margin-left: auto;
		color: #fff;
		position: fixed;
		right: 5px;
		top: 0.625rem;
	}
	.myAccordian {
		display: block;
		color: #f5f8f8
	}
	.cross-button {
		display: block;
		position: absolute;
		left: 21px;
		font-size: 45px;
		color: #ffffff;
	}
	.main-wrapper {
		height: 100%;
		width: 100%;
	}
	.sidebar-inner {
		padding-top: 80px;
		bottom: 0;
		height: 100vh;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	a.mobile_btn:hover {
		color: #fff;
	}
	a.mobile_btn:active {
		color: #BEF202;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		height: 40px;
	}
	/* .header-right {
		display: none;
	} */
	a.mobile_btn {
		color: #fff;
	}
	.notifications:before, .notifications:after {
		right: 60px;
	}
	.modal-body.card-box {
		background-color: #fff;
		border: none;
		border-radius: inherit;
		box-shadow: unset;
		margin-bottom: 0;
		padding: 15px;
	}
	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs.nav-justified>li>a.active, .nav-tabs.nav-justified>li>a.active:hover, .nav-tabs.nav-justified>li>a.active:focus {
		border-color: transparent transparent transparent #1B676B;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs>li>a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs>li>a:hover, .nav-tabs>li>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs>li>a.active, .nav-tabs>li>a.active:hover, .nav-tabs>li>a.active:focus {
		background-color: #f5f5f5;
		border-color: transparent transparent transparent #1B676B;
		border-left-width: 2px;
	}
	.nav-tabs>li.open:not(.active)>a, .nav-tabs>li.open:not(.active)>a:hover, .nav-tabs>li.open:not(.active)>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs.nav-tabs-solid>li>a {
		border-left-width: 0!important;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified>li>a.active, .nav-tabs-justified>li>a.active:hover, .nav-tabs-justified>li>a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #1B676B;
	}
	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.5em;
	}
	h3 {
		font-size: 1.17em;
	}
	h4 {
		font-size: 1.12em;
	}
	h5 {
		font-size: .83em;
	}
	h6 {
		font-size: .75em;
	}
	.page-title {
		font-size: 1.2em;
		padding-top: 0.625rem;
	}
	.card-title {
		font-size: 1.12em;
	}
	.blog-view .blog-title {
		font-size: 24px;
	}
	.table-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.week-calendar {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.title>.second-div {
		display: none;
	}
	.page-wrapper>.content, .page-wrapper>.content-no-padding {
		padding: 20px 20px 20px 20px;
	}
	.closeBtn {
		margin-right: auto;
		color: #fff;
		position: fixed;
		right: 0.625rem;
		line-height: 60px;
		padding: 20px 0.625rem;
	}
	.addBtn {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		padding: 12px;
		margin-left: auto;
		color: #fff;
		position: fixed;
		right: 5px;
		top: 0.625rem;
	}
	.myAccordian {
		display: block;
		color: #f5f8f8
	}
	.main-wrapper {
		height: 100%;
		width: 100%;
	}
	.sidebar-inner {
		padding-top: 80px;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		height: 40px;
	}
	/* .header-right {
		display: none;
	} */
	.panel {
		width: 100%;
	}
	.panel-body {
		margin-right: 0px;
	}
	.profile-info-left {
		border-right: none;
		border-bottom: 2px dashed #ccc;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	.card-left {
		width: 70%;
		padding: 2px;
	}
	.card-right {
		width: 30%;
		padding: 0.625rem;
	}
	.card-right button {
		margin-top: 0.625rem;
	}
	.profile-rightbar {
		display: inline-block !important;
	}
	.message-view.chat-view {
		width: 100%;
	}
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	.sidebar-menu li.active a {
		color: #fff;
		background-color: #195659;
		margin-right: 20px;
	}
	.card-right a {
		display: block;
		margin-bottom: 0.625rem;
		margin-left: auto;
	}
	.mobile_btn {
		display: block;
	}
	.page-title-box {
		display: none;
	}
	.main-wrapper {
		width: 100%;
		height: 100%;
	}
	.page-wrapper {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		// padding-top: 70px;
	}
	.slide-nav .sidebar {
		margin-left: 0;
	}
	a.mobile_btn {
		color: #fff;
		font-size: 25px;
		height: 60px;
		line-height: 25px;
		padding: 15px 0.625rem;
		margin-right: 0px;
		position: absolute;
		left: 0;
		width: 60px;
		z-index: 10;
	}
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	#toggle_btn {
		display: none;
	}
	.page-wrapper {
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.btn-primary-two {
		border: 2px solid #BEF202;
		color: #BEF202 !important;
		width: 40px;
		height: 40px;
		padding: 0;
    font-size: 2rem;
    line-height: 1.2;
	}
	.header-right {
		padding: 8px 10px;
    display: inline-flex;
	}
	.btn-primary-two i {
		font-size: 20px;
		line-height: 30px;
		font-weight: 900;
		color: #BEF202;
	}
	.detail-table{
		display: table;
	}
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 0px) and (max-width: 480px) {
	.page-wrapper>.content {
		padding: 0px;
	}
	.page-wrapper>.content-no-padding {
		margin-left: 0px;
	}
	.sidebar {
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		transition: all 0.4s ease;
		margin-left: -100%;
		width: 100%;
	}
	.sidebar.opened {
		margin-left: 0;
		transition: all 0.4s ease;
		font-family: Poppins-Regular;
		background-color: transparent;
		bottom: 0;
		position: fixed;
		height: 100%;
	}
	.header {
		height: 60px;
		line-height: 60px;
		display: block;
		background: #1B676B;
		position: fixed;
		top: 0;
		border-radius: 0px;
		z-index: 1039;
		width: 100%;
		margin: 0 auto;
	}
	.data-wrapper {
		display: none;
	}
	.header-left{
		max-width: 80%;
		overflow: hidden;
		margin-left: 15px;
	}
	.header-left .title {
		display: inline-block;
		font-size: 1rem;
		line-height: 60px;
		color: #fff;
		padding-left: 0.625rem;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.btn-primary-one {
		margin-top: 0.625rem;
	}
	.header-center {
		display: none;
	}
	.tab-content {
		padding: 0.625rem;
	}
	.cross-button {
		display: block;
		position: absolute;
		left: 21px;
		font-size: 45px;
		color: #ffffff;
	}
	.sidebar-inner {
		padding-top: 80px;
		bottom: 0;
		height: 100%;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	a.mobile_btn:hover {
		color: #fff;
	}
	a.mobile_btn:active {
		color: #BEF202;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		height: 40px;
	}
	/* .header-right {
		display: none;
	} */
	a.mobile_btn {
		color: #fff;
	}
	.modal-body.card-box {
		background-color: #fff;
		border: none;
		border-radius: inherit;
		box-shadow: unset;
		margin-bottom: 0;
		padding: 15px;
	}
	.page-title {
		font-size: 1.2em;
		padding-top: 0.625rem;
	}
	.card-title {
		font-size: 1.12em;
	}
	.table-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.week-calendar {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.title>.second-div {
		display: none;
	}
	.page-wrapper>.content, .page-wrapper>.content-no-padding {
		padding: 20px 20px 20px 20px;
	}
	.closeBtn {
		margin-right: auto;
		color: #fff;
		position: fixed;
		right: 0.625rem;
		line-height: 60px;
		padding: 20px 0.625rem;
	}
	.addBtn {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		padding: 12px;
		margin-left: auto;
		color: #fff;
		position: fixed;
		right: 5px;
		top: 0.625rem;
	}
	.myAccordian {
		display: block;
		color: #f5f8f8
	}
	.cross-button {
		display: none;
	}
	.sidebar-inner {
		padding-top: 80px;
		z-index: 999;
		transition: all 0.2s ease-in-out 0s;
	}
	.dispButton {
		margin-left: auto;
		display: flex;
		padding-bottom: 20px;
	}
	.saveBtn {
		height: 40px;
	}
	/* .header-right {
		display: none;
	} */
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	.sidebar-menu li.active a {
		color: #fff;
		background-color: #195659;
		margin-right: 20px;
	}
	.mobile_btn {
		display: block;
	}
	.main-wrapper {
		width: 200px;
		height: 100%;
	}
	.page-wrapper {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
	.slide-nav .sidebar {
		margin-left: 0;
	}
	a.mobile_btn {
		color: #fff;
		font-size: 25px;
		height: 60px;
		line-height: 25px;
		padding: 15px 0.625rem;
		margin-right: 0px;
		position: absolute;
		left: 0;
		width: 60px;
		z-index: 10;
	}
	.slide-nav .page-wrapper {
		left: 225px;
		margin-left: 0;
	}
	#toggle_btn {
		display: none;
	}
	.page-wrapper {
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}

	.width100{
		padding: 10px;
	}
	.padding10{
		padding: 10px;
	}
	.patient-table-mobile{
		display: table;
		width: 100%;
	}
	/* table.patient-table-mobile tbody{
		background-color: #FFFFFF;
		border-bottom: 25px solid #f5f8f8;
	}
	.detail-table{
		display: table;
	}
	table.detail-table {
		border-collapse: collapse;
		border-spacing: 0;
		background-color: #FFFFFF;
	}
	table.detail-table tbody{
		border-bottom: 25px solid #f5f8f8;
	} */
	.paddingZero{
		padding: 0%;
	}
	.filtersMobile {
		display: block;
	}
	.break-flex {
    flex-basis: 100%;
		height: 0;
		margin: 0;
		margin-top: 0.625rem;
	}
	.border-filter{
		margin: 0;
		padding: 5px;
		height: 35px;
	}
	table.detail-table tr td{
		padding: 10px;
	}
	.mobile-filter-header{
	height: 85px;
    display: block;
    background: #f5f8f8;
    position: fixed;
    top: 60px;
    border-radius: 0px;
    z-index: 995;
    width: 100%;
    margin: 0 auto;
	}

	.navbar{
		padding: .5rem .5rem;
	}

}


@media (min-width: 0px) and (max-width: 320px) {

	.btn-order,
	.btn-primary-dark-order{
		min-width: 100px;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
	}

	.order-label{
		font-size: 12px;
		white-space: break-spaces;
	}
}


/* Landscape */
 
@media only screen
and (min-device-width: 320px)
and (max-device-width: 500px)
and (orientation: landscape) {
 .page-wrapper{
	padding: 0;
 }

.font-16{
	font-size: 16px;
}
}

.nod-black-text{
	color: #000000ab;
}


@media (min-width: 0px) and (max-width: 600px) {
	.bs-datepicker{
		height: 500px;
		overflow: auto;
	}
}
/* html.swal2-shown,body.swal2-shown { overflow-y: visible !important; height: auto!important;} */
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) { overflow-y: visible !important; }
.v-num{
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	margin-top: 30px;
	color: #BEF202;
	font-size: 14px;
}

.checkbox-container{
    padding-left: 12%;
}

.check-block{
    padding-top: 2%;
    padding-left: 27%;
    padding-right: 15%;
    overflow: hidden;
}

.checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 2rem;
    color: #1B676B;
    cursor: pointer;
}

.checkbox--disabled {
    color: 959495;
}

.checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    border: 0.09em solid currentColor;
}

.checkbox__control svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
}

.checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";
}

.checkbox__label {
    line-height: normal;
    font-size: 1.5rem;
}

.checkbox__input>* {
    grid-area: checkbox;
}

.checkbox__input input {
    opacity: 0;
    width: 1em;
    height: 1em;
}

.checkbox__input input:focus+.checkbox__control {
    box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
}

.checkbox__input input:checked+.checkbox__control svg {
    transform: scale(1);
}

.checkbox__input input:disabled+.checkbox__control {
    color: var(--disabled);
}

.checkbox-block {
    margin: 1rem 0;
	height: auto;
}

.info-icon {
    padding: 10px;
    padding-left: 1rem;
    color: #9cb1b3;
}


.mat-chip-list-wrapper input::placeholder{
	color: #9CB1B3;
	font-size: 14px;
}

button[disabled] {
  opacity: 0.7;
  cursor: default !important;
}

[appOverflowTooltip] {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

nodbookings-root .backcrop {
	z-index: 2000 !important;
}

nodbookings-root .popup {
	z-index: 2001 !important;
}

@media (min-width: 0px) and (max-width: 1023px){
//  .navbar-expand-lg .navbar-collapse{
// 	display: block !important;
// }

// .mobile-filter-header {
//     top: 82px; 
// }

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-expand-lg .navbar-toggler {
    display: block;
}

.navbar-expand-lg{
	justify-content: space-between;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: column;
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
	flex-flow: nowrap;
}

.navbar-expand-lg .navbar-collapse{
	flex-basis: 100%;
}

.collapse:not(.show) {
    display: none;
}

}

.error-fields-dialog mat-dialog-container {
	background-color: #fff;
	width: 400px;
	height: auto;
	border-radius: 20px;
	position: relative;
}




/* 
*  Adding CSS for modals ->  Appointment booking
*/

.group-name {
	position: absolute;
	text-align: center;
	display: block;
	padding: 0;
  }
  
//   .close-moodal {
// 	float: right;
// 	display: inline;
// 	text-align: right;
// 	cursor: pointer;
// 	padding-right: 1px;
// 	padding-top: 0;
//   }
  
//   .close-moodal i {
// 	padding: 5px 5px 5px 5px;
//   }
  
  .modal-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #e9ecef;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	padding-bottom: 5px;
	padding-top: 5px;
	padding-right: 0;
	color: #9cb1b3;
	line-height: 25px;
	vertical-align: middle;
  }
  
  .subTitle {
	margin: 30px 0px;
	height: 40px;
	width: 100%;
	color: #9cb1b3;
	font-size: 14px;
	line-height: 20px;
	border-bottom: 2px solid #9cb1b3;
  }
  
  .subTitle input {
	width: 100%;
	padding: 0px 5px;
  }
  
  .modal-btn {
	color: #1b676b;
  }
  
  .modal__heading {
	color: #1b676b;
	font-size: large;
	padding-left: 20px;
  }
  
  .register-box {
	background-color: #fff;
	border: 1px solid #dde4e5;
	max-width: 60%;
	margin: 0 auto;
	border-radius: 10px;
	padding: 0px;
  }
  
  .signup-form-header {
	text-align: center;
	margin: 0 10px;
	padding: 0 10px;
  }
  
  .signup-form-header h1 {
	font-size: 1.875rem;
  }
  
  .vl {
	border-left: 6px solid green;
	height: 500px;
	position: absolute;
	left: 50%;
	margin-left: -3px;
	top: 0;
  }
  
  .border1 {
	border-right: 1px solid #bdcbcc;
	margin: 5px 0px 0px;
  }
  
  input[type="text"] {
	width: 100%;
	height: 36px;
	background: transparent;
  }
  
  .model__input {
	width: 100%;
	height: 35px;
	background: transparent;
	border-bottom: 2px solid #bdcbcc;
  }
  
  .model-body-border {
	padding-bottom: 10px;
	border-bottom: 1px solid #e9ecef;
  }
  
  select {
	margin: 5px;
  }

nodbookings-root .popup {
	z-index: 2001 !important;
  }
  
  [appOverflowTooltip] {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
//   @media (max-width: 1280px) {
// 	nodbookings-root .nodbookings {
// 	  padding-left: 200px;
// 	}
  
// 	nodbookings-root .nodbookings nodbookings-search-results-filter {
// 	  padding-left: 100px;
// 	}
//   }
  
  @media (max-width: 1023px) {
	nodbookings-root .nodbookings {
	  padding-left: 0 !important;
	  padding-top: 65px !important;
	}
  
	nodbookings-root .nodbookings nodbookings-search-results-filter {
	  padding-left: 0 !important;
	}
  
	nodbookings-root
	  .nodbookings
	  nodbookings-search-results-filter
	  .search-results-filter {
	  margin-top: 60px !important;
	  height: calc(100% - 60px) !important;
	}
  }
  
  @media (min-width: 800px) and (max-width: 1021px) {
	.mat-spinner {
	  left: 45%;
	}
  }
  
  @media (min-width: 545px) and (max-width: 799px) {
	.mat-spinner {
	  left: 44%;
	}
  }
  
  @media (min-width: 417px) and (max-width: 544px) {
	.mat-spinner {
	  left: 42%;
	}
  }
  
  @media (min-width: 200px) and (max-width: 416px) {
	.mat-spinner {
	  left: 38%;
	}
  }
  
  
  .pointer-btn{
	cursor: pointer;
  }


  //mat dialog css
  .mat-dialog-container {
	background: #fff;
  }

  .mat-dialog-container{
	box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
	color: rgba(0,0,0,.87);
  }

  .cdk-overlay-pane{
	position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
  
  .message-soon{
	font-size: 20px;
    text-align: center;
	color: #1b676b;
  }

  .model-btn {
    justify-content: center;
    font-family: Poppins-Medium;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    display: flex;
    height: 35px;
    background-color: #1b676b;
    border-radius: 25px;
    transition: all .4s;
    cursor: pointer;
    width: 100px;
}

.mat-snack-bar-container {
	color: #fff;
	font-weight: 600;
	font-size: 18px;
  }
  
  .mat-snack-bar-handset .mat-snack-bar-container {
	margin-top: 40px !important;
  }
  .mat-snack-bar-container {
	margin-right: 30% !important;
  }
  
  simple-snack-bar span {
	overflow: hidden;
	text-overflow: ellipsis;
  }



// .tool-wrapper .tooltip-bottom {
//     background: #bef202;
//     left: 0;
//     right: 0;
// 	top:0;
//     margin-top: 5px;
//     width: 350px;
// 	height: 4rem;
// }

.specialist-close-btn{
	float:right;
}

.specialist-model-btn{
align-items: center;
}

.tool-wrapper .specialist-tooltip{
	top:0 !important;
	height: 63px !important;
}
.btn-nod-primary {
	background-color: #195659;
	color: white;
	font-weight: 600;
	border-radius: 25px;	
	transition: all 0.5s;
	min-width: 114.21px;
	width: 20%;
	height: 44px;
  }
  .csv-button {
	padding: 5px 35px;
	border: 5px solid #BEF202;
	background-color: #BEF202;
	border-radius: 25px;
	font-weight: 600;
	color: #1b676b;
	margin-bottom: 20px;
  }
